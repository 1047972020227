<template>
  <div class="box_1">
    <div class="header">
      <div class="text" @click="goToMenuPage()">巴林右旗{{ mapName || '乡镇' }}农业统计</div> 
    </div>

    <div class="content_box">
      <!-- 1 -->
      <div class="content_item_box">
        <div class="left_box">
          <div class="title"><span>种植种类</span></div>
          <!-- <bing-tu :value="data1" /> -->
          <kong-xin-bing-tu :value="data1" />
        </div>

        <div class="left_box">
          <div class="title"><span>种植人口</span></div>
          <bing-tu :value="data2" />
        </div>
      </div> 

      <!-- 2 -->
      <div class="content_item_box">
        <div class="map_box">
          <!-- <ba-lin-you-qi-map /> -->
          <da-ban-map v-if="mapName == '大板镇'" />
          <su-bo-ri-ga v-if="mapName == '索博日嘎镇'" />
          <xing-fu-zhi-lu v-if="mapName == '幸福之路苏木'" />
          <ba-yan-hu-shuo-hamlet v-if="mapName == '巴彦琥硕镇'" />
          <cha-gan-mu-lun-hamlet v-if="mapName == '查干沐沦苏木'" />
          <mei-dian-hua v-if="mapName == '大板煤电化基地'" />

          <ba-yan-ta-la v-if="mapName == '巴彦塔拉苏木'" />
          <cha-gan-nuo-er v-if="mapName == '查干诺尔镇'" />
          <bao-ri-wu-su v-if="mapName == '宝日勿苏镇'" />
          <xi-la-mu-lun v-if="mapName == '西拉沐沦苏木'" />

          <!-- <div class="map">
            <img src="../../../../assets/images/ba-lin-you-qi/map.png" alt="">
          </div> -->
        </div>

        <div class="context_box">
          全镇总土地面积 1925 平方公里，
          全镇大小畜存栏 22.6 万头只，其中大畜牛 3.58 万头。
          永久性棚圈建设 5.3 万平方米，青贮窖建设 8300 立方米。
          至 2022 年人口 77003 人。总人口中男性 38520 人。
        </div>

        <div class="bottom_box">
          <div class="title"><span>产量统计</span></div>
          <zhu-zhaung-tu class="chart" :value="data3" />
        </div>
      </div> 

      <!-- 3 -->
      <div class="content_item_box">
        <div class="right_box">
          <div class="title"><span>病虫害统计</span></div>
          <!-- <zhu-zhaung-tu class="chart" :value="data4" /> -->
          <ban-bing-tu :value="data6" />
        </div>

        <div class="right_box">
          <div class="title"><span>机械化统计</span></div>
          <zhu-zhaung-tu class="chart" :value="data5" />
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
  import ZhuZhaungTu from '../charts/zhu-zhuang-tu.vue'
  import BaLinYouQiMap from '../maps/baLinYouQiMap.vue'
  import BingTu from '../charts/bing-tu.vue'
  import DaBanMap from '../maps/daBanMap.vue'
  import SuBoRiGa from '../maps/suBoRiGa.vue'
  import XingFuZhiLu from '../maps/xingFuZhiLu.vue'
  import BaYanHuShuoHamlet from '../maps/ba-yan-hu-shuo-hamlet.vue'
  import ChaGanMuLunHamlet from '../maps/cha-gan-mu-lun-hamlet.vue'
  import MeiDianHua from '../maps/mei-dian-hua.vue'
  import BaYanTaLa from '../maps/ba-yan-ta-la.vue'
  import BaoRiWuSu from '../maps/bao-ri-wu-su.vue'
  import XiLaMuLun from '../maps/xi-la-mu-lun.vue'
  import ChaGanNuoEr from '../maps/cha-gan-nuo-er.vue'
  import kongXinBingTu from '../charts/kong-xin-bing-tu.vue'
import BanBingTu from '../charts/ban-bing-tu.vue'

  export default {
    name: 'showPage1',
    components: { 
      BaLinYouQiMap, 
      ZhuZhaungTu, 
      BingTu, 
      DaBanMap, 
      SuBoRiGa, 
      XingFuZhiLu, 
      BaYanHuShuoHamlet,
      ChaGanMuLunHamlet,
      MeiDianHua,
      BaYanTaLa,
      BaoRiWuSu,
      XiLaMuLun,
      ChaGanNuoEr, 
      kongXinBingTu,
      BanBingTu
    },
    data() {
      return {
        activeIndex: 0,
        mapName: '大板镇',

        data1: {},
        data2: {},
        data3: {},
        data4: {},
        data5: {},
        data6: {},

        personList: [
          { name: '20岁以下', value: 4133 },
          { name: '20 - 30岁', value: 4128 },
          { name: '30 - 50岁', value: 10058 },
          { name: '50岁以上', value: 5352 }
        ],
        mashineList: [
          { name: ' 四轮车', value: 3500 },
          { name: '大型货车', value: 18 },
          { name: '大型拖拉机', value: 91 },
          { name: '播种机械', value: 2800 },
          { name: '打草机械', value: 580 },
          { name: '家用车辆', value: 450 },
        ]
      }
    },
    mounted() {
      setTimeout(() => {
        this.initChart()
      }, 100)

      this.mapName = this.$route.params.val

      // this.$bus.$on('setData', val => {
      //   this.mapName = val.mapName
      //   this.data1 = val.zhong_zhi_zhong_lei
      //   this.data2 = val.yang_zhi_lei
      //   this.data3 = val.chan_liang
      //   this.data4 = val.bing_chong_hai
      //   this.data5 = val.zhi_neng_hua
      //   this.personList = val.person_data
      //   this.mashineList = val.mashine_data
      // })
    },
    methods: {
      // 初始化图表
      initChart() {
        this.data1 = {
          title: '种植种类',
          unit: '万亩',
          data: [
            {"name": "水稻", "value": 2.6, "unit": ''},
            {"name": "甜菜", "value": 1.23, "unit": ''},
            {"name": "西瓜", "value": 0.9, "unit": ''},
            {"name": "香瓜", "value": 0.6, "unit": ''},
          ]
        }

        this.data2 = {
          title: '种植人口',
          unit: '人',
          data: [
            {"name": "水稻", "value": 65892, "unit": ''},
            {"name": "甜菜", "value": 32435, "unit": ''},
            {"name": "西瓜", "value": 43353, "unit": ''},
            {"name": "香瓜", "value": 33232, "unit": ''},
          ]
        }

        this.data3 = {
          title: {
            text: "产量统计",
            left: "center",
            top: '0%',
            textStyle: {
              color: "#fff"
            }
          },
          yName: '吨',
          x: ['玉米', '甜玉米', '西瓜', '水稻', '甜菜', '葵花', '马铃薯', '香瓜'],
          y: [98, 69, 112, 43, 324, 142, 67, 87, 89]
        }

        this.data4 = {
          title: {
            text: "病虫害统计",
            right: "20px",
            top: '0%',
            textStyle: {
              color: "#fff"
            }
          },
          yName: '万亩',
          x: ['小麦锈病', '稻瘟病', '稻纹枯病', '蝗虫', '麦芽'],
          y: [18, 39, 12, 43, 24]
        }

        this.data5 = {
          title: {
            text: "智能化统计",
            right: "20px",
            top: '0%',
            textStyle: {
              color: "#fff"
            }
          },
          yName: '件',
          x: ['收割机', '打草机', '播种机', '消毒机', '大型拖拉机'],
          y: [24, 56, 122, 43, 124]
        }

        this.data6 = {
          title: '病虫害统计',
          unit: '万亩',
          data: [
            {"name": "小麦锈病", "value": 18, "unit": ''},
            {"name": "稻瘟病", "value": 39, "unit": ''},
            {"name": "稻纹枯病", "value": 12, "unit": ''},
            {"name": "蝗虫", "value": 43, "unit": ''},
            {"name": "麦芽", "value": 24, "unit": ''}
          ]
        }
      },

      // 跳转到 menu 页面
      goToMenuPage() {
        this.$router.push({
          path: "/bigscreen/menuPage"
        })
      },

      clickTab(val) {
        this.activeIndex = val
        if (val == 0) {
          this.list = this.list1
        } else if (val == 1) {
          this.list = this.list2
        } else if (val == 2) {
          this.list = this.list3
        } else if (val == 3) {
          this.list = this.list4
        } else if (val == 4) {
          this.list = this.list5
        } else if (val == 5) {
          this.list = this.list6
        }
      },

      // 跳转到 人口统计 图表详情
      goToPersonStateDetail() {
        // let val = {
        //   title: '乡镇人口统计',
        //   yName: '人',
        //   x: ['20岁以下', '20-30岁', '30-50岁', '50岁以上'],
        //   y: [4133, 4128, 10058, 5352]
        // }

        let title = `${this.mapName}人口统计`

        this.$router.push({
          path: "/bigscreen/personState" + title
        })
      },

      // 跳转到 机械化统计 图表详情
      goToMashineStateDetail() {
        // let val = {
        //   title: '乡镇机械化统计',
        //   yName: '辆',
        //   x: ['四轮车', '大型货车', '大型拖拉机', ' 播种机械', '打草机械', '家用车辆'],
        //   y: [3500, 18, 91, 2800, 580, 450]
        // }
        // this.$router.push({
        //   path: "/bigscreen/personState" + JSON.stringify(val)
        // })

        let title = `${this.mapName}机械化统计`

        this.$router.push({
          path: "/bigscreen/mashineState" + title
        })
      }
    }
  }
</script>

<style scoped lang="less">
  .activeColor { color: #2366fc !important; }

  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    .bottom_box {
      height: 42% !important;
    }

    .chart { height: 100% !important; top: -12% !important; }
  }
</style>